<template>
	<section id="home" class="py-5" style="background-color: #3f5fb9;">
		<v-container fluid>
			<v-row class="justify-space-around">
				<v-col lg="5" class="my-auto" style="color: white">
					<div>
						<h1 style="font-family: Tahoma, Geneva, sans-serif">{{ $t('app.enterprise.name') }}</h1>
						<br />
						<p>
							OverSOS es la plataforma que conecta al estudiante directamente con el mundo laboral. ¡Encontrar talento nunca había sido tan fácil!
						</p>
					</div>
					<br />
					<div class="d-flex align-center">
						<div class="text-uppercase text-white">
							¿eres estudiante? Ve a
						</div>
						<v-btn color="red lighten-1" rounded class="ml-3" href="https://oversos.com" target="_blank">
							<span>OVERSOS.COM</span>
						</v-btn>
					</div>
				</v-col>
				<v-col lg="5">
					<LoginForm color="transparent" :dark="true" />
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
export default {
	name: 'LandingHome',
	components: {
		LoginForm: () => import('@/components/auth/LoginForm.vue')
	}
}
</script>
